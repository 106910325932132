let mock = [
  {
    id: 1,
    name: "Iron Man",
    release: "May 2, 2008",
    director: ["Jon Favreau"],
    producer: ["Avi Arad", "Kevin Feige"],
    duration: "2h 6min",
    genre: ["Action", "Adventure", "Sci-Fi"],
    imdb: 7.9,
    metascore: 79,
    cast: ["Robert Downey Jr.", "Gweneth Paltrow", "Terrence Howard"],
    budget: 140000000,
    domestic: 318604126,
    gross: 585366247,
    opening: 98618668,
    nominations: 2,
    oscars: 0,
    phase: 1,
    image: "iron_man_1.jpg"
  },
  {
    id: 2,
    name: "The Incredible Hulk",
    release: "June 13, 2008",
    director: ["Louis Leterrier"],
    producer: ["Avi Arad", "Gale Ann Hurd", "Kevin Feige"],
    duration: "1h 52min",
    genre: ["Action", "Adventure, Sci-Fi"],
    imdb: 6.6,
    metascore: 61,
    cast: ["Edward Norton", "Liv Tyler", "Tim Roth"],
    budget: 150000000,
    domestic: 134806913,
    gross: 264770996,
    opening: 55414050,
    nominations: 0,
    oscars: 0,
    phase: 1,
    image: "hulk_1.jpg"
  },
  {
    id: 3,
    name: "Iron Man 2",
    release: "May 7, 2010",
    director: ["Jon Favreau"],
    producer: ["Kevin Feige"],
    duration: "2h 4min",
    genre: ["Action", "Adventure, Sci-Fi"],
    imdb: 7,
    metascore: 57,
    cast: ["Robert Downey Jr.", "Gweneth Paltrow", "Mickey Rourke"],
    budget: 200000000,
    domestic: 312433331,
    gross: 623933331,
    opening: 128122480,
    nominations: 1,
    oscars: 0,
    phase: 1,
    image: "iron_man_2.jpg"
  },
  {
    id: 4,
    name: "Thor",
    release: "May 6, 2011",
    director: ["Kenneth Branagh"],
    producer: ["Kevin Feige"],
    duration: "1h 55min",
    genre: ["Action", "Adventure, Fantasy"],
    imdb: 7,
    metascore: 57,
    cast: ["Chris Hemsworth", "Anthony Hopkins", "Natalie Portman"],
    budget: 150000000,
    domestic: 181030624,
    gross: 449326618,
    opening: 65723338,
    nominations: 0,
    oscars: 0,
    phase: 1,
    image: "thor_1.jpg"
  },
  {
    id: 5,
    name: "Captain America: The First Avenger",
    release: "July 22, 2011",
    director: ["Joe Johnston"],
    producer: ["Kevin Feige"],
    duration: "2h 4min",
    genre: ["Action", "Adventure, Sci-Fi"],
    imdb: 6.9,
    metascore: 66,
    cast: ["Chris Evans", "Hugo Weaving", "Samuel L. Jackson"],
    budget: 140000000,
    domestic: 176654505,
    gross: 370569774,
    opening: 65058524,
    nominations: 0,
    oscars: 0,
    phase: 1,
    image: "captain_america_1.jpg"
  },
  {
    id: 6,
    name: "Marvel's the Avengers",
    release: "May 4, 2012",
    director: ["Joss Whedon"],
    producer: ["Kevin Feige"],
    duration: "2h 23min",
    genre: ["Action", "Adventure, Sci-Fi"],
    imdb: 8,
    metascore: 69,
    cast: ["Robert Downey Jr.", "Chris Evans", "Scarlett Johansson", "Jeremy Renner"],
    budget: 220000000,
    domestic: 623357910,
    gross: 1518812988,
    opening: 207438708,
    nominations: 1,
    oscars: 0,
    phase: 1,
    image: "avengers_1.jpg"
  },
  {
    id: 7,
    name: "Iron Man 3",
    release: "May 3, 2013",
    director: ["Shane Black"],
    producer: ["Kevin Feige"],
    duration: "2h 10min",
    genre: ["Action", "Adventure, Sci-Fi"],
    imdb: 7.1,
    metascore: 62,
    cast: ["Robert Downey Jr.", "Gweneth Paltrow", "Guy Pearce"],
    budget: 200000000,
    domestic: 408992272,
    gross: 1214811252,
    opening: 174144585,
    nominations: 1,
    oscars: 0,
    phase: 2,
    image: "iron_man_3.jpg"
  },
  {
    id: 8,
    name: "Thor: The Dark World",
    release: "November 8, 2013",
    director: ["Alan Taylor"],
    producer: ["Kevin Feige"],
    duration: "1h 52min",
    genre: ["Action", "Adventure, Fantasy"],
    imdb: 6.8,
    metascore: 54,
    cast: ["Chris Hemsworth", " Natalie Portman", "Tom Hiddleston"],
    budget: 170000000,
    domestic: 206362140,
    gross: 644783140,
    opening: 85737841,
    nominations: 0,
    oscars: 0,
    phase: 2,
    image: "thor_2.jpg"
  },
  {
    id: 9,
    name: "Captain America: The Winter Soldier",
    release: "April 4, 2014",
    director: ["Anthony Russo", "Joe Russo"],
    producer: ["Kevin Feige"],
    duration: "2h 16min",
    genre: ["Action", "Adventure", "Sci-Fi"],
    imdb: 7.7,
    metascore: 70,
    cast: ["Chris Evans", "Scarlett Johansson", "Samuel L. Jackson"],
    budget: 170000000,
    domestic: 259746958,
    gross: 714421503,
    opening: 95023721,
    nominations: 1,
    oscars: 0,
    phase: 2,
    image: "captain_america_2.jpg"
  },
  {
    id: 10,
    name: "Guardians of the Galaxy",
    release: "August 1, 2014",
    director: ["James Gunn"],
    producer: ["Kevin Feige"],
    duration: "2h 1min",
    genre: ["Action, Adventure", "Comedy"],
    imdb: 8,
    metascore: 76,
    cast: ["Chris Pratt", "Vin Diesel", "Bradely Cooper"],
    budget: 170000000,
    domestic: 333714112,
    gross: 772776600,
    opening: 94320883,
    nominations: 2,
    oscars: 0,
    phase: 2,
    image: "guardians_1.jpg"
  },
  {
    id: 11,
    name: "Avengers: Age of Ultron",
    release: "May 1, 2015",
    director: ["Joss Whedon"],
    producer: ["Kevin Feige"],
    duration: "2h 21min",
    genre: ["Action", "Adventure, Sci-Fi"],
    imdb: 7.3,
    metascore: 66,
    cast: ["Robert Downey Jr.", "Chris Evans", "Chris Hemsworth", "Mark Ruffalo"],
    budget: 250000000,
    domestic: 459005868,
    gross: 1402805868,
    opening: 191271109,
    nominations: 0,
    oscars: 0,
    phase: 2,
    image: "avengers_2.jpg"
  },
  {
    id: 12,
    name: "Ant Man",
    release: "July 17, 2015",
    director: ["Peyton Reed"],
    producer: ["Kevin Feige"],
    duration: "1h 57min",
    genre: ["Action, Adventure", "Comedy"],
    imdb: 7.3,
    metascore: 64,
    cast: ["Paul Rudd", "Michael Douglas", "Corey Stall"],
    budget: 130000000,
    domestic: 180202163,
    gross: 519311965,
    opening: 57225526,
    nominations: 0,
    oscars: 0,
    phase: 2,
    image: "ant_man_1.jpg"
  },
  {
    id: 13,
    name: "Captain America: Civil War",
    release: "May 6, 2016",
    director: ["Anthony Russo", "Joe Russo"],
    producer: ["Kevin Feige"],
    duration: "2h 27min",
    genre: ["Action", "Adventure, Sci-Fi"],
    imdb: 7.8,
    metascore: 75,
    cast: ["Robert Downey Jr.", "Chris Evans", "Scarlett Johansson", "Sebastian Stan"],
    budget: 250000000,
    domestic: 408084349,
    gross: 1153296293,
    opening: 179139142,
    nominations: 0,
    oscars: 0,
    phase: 3,
    image: "civil_war.jpg"
  },
  {
    id: 14,
    name: "Doctor Strange",
    release: "November 4, 2016",
    director: ["Scott Derrickson"],
    producer: ["Kevin Feige"],
    duration: "1h 55min",
    genre: ["Action", "Adventure, Fantasy"],
    imdb: 7.5,
    metascore: 72,
    cast: ["Benedict Cumberbatch", "Chiswetel Ejiofor", "Racheal McAdams"],
    budget: 165000000,
    domestic: 232641920,
    gross: 677718395,
    opening: 85058311,
    nominations: 1,
    oscars: 0,
    phase: 3,
    image: "doctor_strange.jpg"
  },
  {
    id: 15,
    name: "Guardians of the Galaxy Vol. 2",
    release: "May 5, 2017",
    director: ["James Gunn"],
    producer: ["Kevin Feige"],
    duration: "2h 16min",
    genre: ["Action", "Adventure", "Comedy"],
    imdb: 7.6,
    metascore: 67,
    cast: ["Chris Pratt", " Zoe Saldana", "Dave Bautista", "Vin Diesel"],
    budget: 200000000,
    domestic: 389813101,
    gross: 863756051,
    opening: 146510104,
    nominations: 1,
    oscars: 0,
    phase: 3,
    image: "guardians_2.jpg"
  },
  {
    id: 16,
    name: "Spider-Man: Homecoming",
    release: "July 7, 2017",
    director: ["Jon Watts"],
    producer: ["Kevin Feige", "Amy Pascal"],
    duration: "2h 13min",
    genre: ["Action", "Adventure", "Sci-Fi"],
    imdb: 7.4,
    metascore: 73,
    cast: ["Tom Holland", "Michael Keaton", "Robert Downey Jr."],
    budget: 175000000,
    domestic: 334201140,
    gross: 880166924,
    opening: 117027503,
    nominations: 0,
    oscars: 0,
    phase: 3,
    image: "spiderman_1.jpg"
  },
  {
    id: 17,
    name: "Thor: Ragnarok",
    release: "November 3, 2017",
    director: ["Taika Waititi"],
    producer: ["Kevin Feige"],
    duration: "2h 10min",
    genre: ["Action", "Adventure", "Comedy"],
    imdb: 7.9,
    metascore: 74,
    cast: ["Chris Hemsworth", "Cate Blanchett", "Tom Hiddleston"],
    budget: 180000000,
    domestic: 315058280,
    gross: 853977126,
    opening: 122744989,
    nominations: 0,
    oscars: 0,
    phase: 3,
    image: "thor_2.jpg"
  },
  {
    id: 18,
    name: "Black Panther",
    release: "February 16, 2018",
    director: ["Ryan Coogler"],
    producer: ["Kevin Feige"],
    duration: "2h 14min",
    genre: ["Action", "Adventure", "Sci-Fi"],
    imdb: 7.3,
    metascore: 88,
    cast: ["Chadwick Boseman", "Michael B. Jordan", "Lupita Nyong'o"],
    budget: 200000000,
    domestic: 700059560,
    gross: 1346913161,
    opening: 202003951,
    nominations: 7,
    oscars: 3,
    phase: 3,
    image: "black_panther.jpg"
  },
  {
    id: 19,
    name: "Avengers: Infinity War",
    release: "April 27, 2018",
    director: ["Anthony Russo", "Joe Russo"],
    producer: ["Kevin Feige"],
    duration: "2h 29min",
    genre: ["Action", "Adventure, Sci-Fi"],
    imdb: 8.4,
    metascore: 68,
    cast: ["Robert Downey Jr.", "Chris Evans", "Chris Hemsworth", "Mark Ruffalo", "Josh Brolin"],
    budget: 300000000,
    domestic: 678815482,
    gross: 2048359754,
    opening: 257698183,
    nominations: 1,
    oscars: 0,
    phase: 3,
    image: "avengers_3.jpg"
  },
  {
    id: 20,
    name: "Ant-Man and the Wasp",
    release: "July 6, 2018",
    director: ["Peyton Reed"],
    producer: ["Kevin Feige", "Stephen Broussard"],
    duration: "1h 58min",
    genre: ["Action", "Adventure", "Comedy"],
    imdb: 7,
    metascore: 70,
    cast: ["Paul Rudd", "Evangeline Lilly", "Micheal Pena"],
    budget: 130000000,
    domestic: 216648740,
    gross: 622674139,
    opening: 75812205,
    nominations: 0,
    oscars: 0,
    phase: 3,
    image: "ant_man_2.jpg"
  },
  {
    id: 21,
    name: "Captain Marvel",
    release: "March 8, 2019",
    director: ["Anna Boden", "Ryan Fleck"],
    producer: ["Kevin Feige"],
    duration: "2h 3min",
    genre: ["Action", "Adventure, Sci-Fi"],
    imdb: 6.8,
    metascore: 64,
    cast: ["Brie Larson", "Samuel L. Jackson", "Ben Mendelsohn"],
    budget: 160000000,
    domestic: 426829839,
    gross: 1128274794,
    opening: 153433423,
    nominations: 0,
    oscars: 0,
    phase: 3,
    image: "captain_marvel_1.jpg"
  },
  {
    id: 22,
    name: "Avengers: Endgame",
    release: "April 26, 2019",
    director: ["Anthony Russo", "Joe Russo"],
    producer: ["Kevin Feige"],
    duration: "3h 1min",
    genre: ["Action", "Adventure", "Drama"],
    imdb: 8.4,
    metascore: 78,
    cast: ["Robert Downey Jr.", "Chris Evans", "Chris Hemsworth", "Mark Ruffalo", "Josh Brolin"],
    budget: 356000000,
    domestic: 858373000,
    gross: 2797501328,
    opening: 357115007,
    nominations: 1,
    oscars: 0,
    phase: 3,
    image: "avengers_4.jpg"
  },
  {
    id: 23,
    name: "Spider-Man: Far From Home",
    release: "July 2, 2019",
    director: ["Jon Watts"],
    producer: ["Kevin Feige", "Amy Pascal"],
    duration: "2h 9min",
    genre: ["Action", "Adventure", "Sci-Fi"],
    imdb: 7.5,
    metascore: 69,
    cast: ["Tom Holland", "Samuel L. Jackson", "Jake Gyllenhaal"],
    budget: 160000000,
    domestic: 390532085,
    gross: 1131927996,
    opening: 92579212,
    nominations: 0,
    oscars: 0,
    phase: 3,
    image: "spiderman_2.jpg"
  },
];

export default mock;
